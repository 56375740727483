@import 'src/styles/variables';

.flex-align-center {
    display: flex;
    align-items: center;
}

.notificationPopover {
    border-radius: 4.8px !important;
    inset: -5px auto auto -30px !important;
    width: 500px !important;
    max-width: 500px !important;
    height: min(77vh, 560px) !important;
    max-height: min(77vh, 560px) !important;
    font-family: $font-family !important;

    .closeIcon {
        cursor: pointer;
    }

    .popover-header {
        border-radius: 4px 4px 0 0 !important;
        background-color: $white;
        height: 45px !important;
        padding: 10px 16px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 20px;
        font-weight: 700;
        line-height: 24px;

        &::before {
            display: none !important;
        }
    }

    .popover-body {
        padding: 10px 0 10px 16px;
        height: calc(100% - 90px) !important;

        &_topSection {
            height: 100%;
            overflow: hidden;
            overflow-y: scroll;
            padding-right: 10px;

            .notificationContainer {
                display: flex;
                padding: 18px 14px;
                min-height: 85px;
                border-bottom: 1px solid #dee2e6;
                cursor: pointer;

                &.unread {
                    background-color: #e6ebf080;
                }

                &.read {
                    background-color: #ffffff;
                }

                &_leftSection {
                    width: 39px;
                    height: 39px;
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-top: 2px;
                    margin-right: 16px;

                    &.clipboardIcon {
                        background-color: #b5d5ea;
                    }

                    &.bellIcon {
                        background-color: #f3e2c3;
                    }
                }

                &_rightSection {
                    &--text {
                        font-size: 16px;
                        font-weight: 600;
                        line-height: 24px;
                        margin-bottom: 4px;
                    }

                    &--clockIcon {
                        margin-right: 8px;
                    }

                    &--time {
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 21px;
                        color: #565a5e;
                        padding-bottom: 1px;
                    }
                }
            }

            .emptyNotification {
                width: 100%;
                height: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                &_headerText {
                    font-size: 24px;
                    font-weight: 300;
                    line-height: 28.8px;
                    margin-top: 23px;
                }

                &_horizontalLine {
                    width: 78px;
                    height: 2px;
                    background-color: #c4c6c8;
                    margin: 23px 0;
                }

                &_message {
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 24px;
                    text-align: center;
                }
            }
        }

        &_bottomSection {
            display: inline-block;
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            color: #05386b;
            text-decoration: underline;
            cursor: pointer;
            padding: 14px 0 0 11px;
        }
    }

    .popover-arrow {
        display: none !important;
    }
}
