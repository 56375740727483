.questionMarkIcon {
	padding-top: 0.5rem;
	margin-bottom: 1.4rem;
}

.recoverOrResetText {
	font-family: Mulish;
	font-size: 16px;
	font-weight: 400;
	line-height: 24px;
	color: #212529;
	margin-top: 10px;
}

.clickHere {
	color: #05386b;
	cursor: pointer;
	text-decoration: underline;
}
