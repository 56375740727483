.emailID {
	width: 90%;
	max-width: 370px;
	min-width: 240px;
	height: 38px;
	border-radius: 4px;
	padding: 6px 12px;
	border: 1px solid #898d91;
	font-family: Mulish;
	font-size: 16px;
	font-weight: 400;
	line-height: 24px;
	color: #565a5e;
	outline: none;
}

.emailID:disabled {
	background-color: #f0f1f1 !important;
	border: 1px solid #c4c6c8 !important;
	color: #c4c6c8;
	cursor: not-allowed;
}

.forgotUserName {
	margin-top: 12px;
}

.continueContainer {
	margin-top: 1.6rem;
	.continue {
		width: 90%;
		max-width: 370px;
		min-width: 240px;
		height: 38px;
		padding: 6px 12px;
		border-radius: 2px;
		border: 1px solid #0973ba;
		background-color: #0973ba;
		font-family: Mulish;
		font-size: 16px;
		font-weight: 400;
		line-height: 24px;
		color: #fff;
		cursor: pointer;
	}
	.continue:disabled {
		border: 1px solid #c4c6c8;
		background-color: #f0f1f1;
		color: #c4c6c8;
		cursor: not-allowed;
	}
}

.registrationContainer {
	margin-top: 12px;

	.text {
		font-family: Mulish;
		font-size: 16px;
		font-weight: 400;
		line-height: 24px;
		color: #565a5e;
	}

	.signUp {
		font-family: Mulish;
		font-size: 16px;
		font-weight: 700;
		line-height: 24px;
		color: #05386b;
		cursor: pointer;
	}
}
.captchaContainer{
	margin-top: 20px;
}