.veryEmailContainer {
	height: 173px;
	width: 85%;
	max-width: 674px;
	min-width: 440px;
	border: 1px solid #898d91;
	border-radius: 4px;
	border-left: 4px solid #0973ba;
	padding: 0.5rem 1rem;
	position: relative;

	.header {
		display: flex;
		justify-content: space-between;
		align-items: flex-start;

		.headerTitle {
			font-family: Mulish;
			font-size: 16px;
			font-weight: 700;
			line-height: 24px;
			color: #212529;
		}

		.headerCloseDisabled {
			cursor: default;
			pointer-events: none;
		}

		.headerClose {
			cursor: pointer;
		}
	}

	.verifyDescription {
		font-family: Mulish;
		font-size: 16px;
		font-weight: 400;
		line-height: 24px;

		span {
			font-weight: 700;
		}
	}

	.codeContainer {
		margin-top: 1rem;
		display: flex;
		align-items: center;

		.verifyCodeInput {
			width: 138px;
			height: 31px;
			padding: 4px 8px;
			border-radius: 3.2px;
			border: 1px solid #898d91;
			font-family: Mulish;
			font-size: 14px;
			font-weight: 400;
			line-height: 21px;
			color: #565a5e;
			outline: none;
		}

		.errorBorder {
			border: 1px solid #cc4a43;
		}

		.verifyCodeButton {
			margin-left: 0.7rem;
			width: 93px;
			height: 31px;
			padding: 4px 8px;
			border-radius: 2px;
			border: 1px solid #0973ba;
			background-color: #0973ba;
			font-family: Mulish;
			font-size: 14px;
			font-weight: 400;
			line-height: 21px;
			color: #fff;
			cursor: pointer;
		}

		.verifyCodeButton:disabled {
			background-color: #f0f1f1 !important;
			border: 1px solid #c4c6c8 !important;
			color: #c4c6c8 !important;
			cursor: not-allowed;
		}
	}

	.codeError {
		font-family: Mulish;
		font-size: 14px;
		font-weight: 400;
		line-height: 21px;
		color: #cc4a43;
		margin-top: 3px;
	}

	.resend {
		position: absolute;
		bottom: 0.5rem;
		font-family: Mulish;
		font-size: 14px;
		font-weight: 400;
		line-height: 21px;
		color: #212529;
		span {
			font-weight: 700;
			color: #0973ba;
			cursor: pointer;
		}
	}
}
