.documentRow {
	height: 41px;
	vertical-align: middle;
	font-family: Mulish;
	font-size: 16px;
	font-weight: 400;
	line-height: 24px;
	border-bottom: 1px solid #a6a9ac;
	cursor: default;

	td {
		vertical-align: middle;

		.nameContainer {
			display: inline-block;
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
			margin-top: 4px;

			svg {
				margin-top: -4px;
				margin-right: 0.5rem;
			}

			.highlight {
				background-color: #fff48d;
			}
		}

		.fileNameContainer {
			display: flex;
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
			margin-top: 4px;

			& > svg:first-child {
				margin-top: 4px;
				margin-right: 0.5rem;
			}
			svg {
				min-width: 8px;
			}

			.highlight {
				background-color: #fff48d;
			}

			& > div {
				overflow: hidden;
				white-space: nowrap;
				text-overflow: ellipsis;	
			}
		}

		.manuallySignedStyle {
			display: inline-block;
			width: 123px;
			height: 23px;
			padding: 1px 0px;
			text-align: center;
			border-radius: 4px;
			border: 1px solid #47682d;
			background-color: #f0f4ec;
			color: #47682d;
			font-family: Mulish;
			font-size: 14px;
			font-weight: 400;
			line-height: 21px;
		}

		.manuallyCompletedStyle {
			display: inline-block;
			width: auto;
			height: 23px;
			padding: 1px 0px;
			text-align: center;
			border-radius: 4px;
			border: 1px solid #47682d;
			background-color: #f0f4ec;
			color: #47682d;
			font-family: Mulish;
			font-size: 14px;
			font-weight: 400;
			line-height: 21px;
			padding: 0 3px;
		}

		.partiallySignedStyle, .awaitingDraftReviewStyle {
			display: inline-block;
			width: 118px;
			height: 23px;
			padding: 1px 0px;
			text-align: center;
			border-radius: 4px;
			border: 1px solid #a9570f;
			background-color: #fbf0e7;
			color: #a9570f;
			font-family: Mulish;
			font-size: 14px;
			font-weight: 400;
			line-height: 21px;
		}

		.eSignedStyle {
			display: inline-block;
			width: 75px;
			height: 23px;
			padding: 1px 0px;
			text-align: center;
			border-radius: 4px;
			border: 1px solid #47682d;
			background-color: #f0f4ec;
			color: #47682d;
			font-family: Mulish;
			font-size: 14px;
			font-weight: 400;
			line-height: 21px;
		}

		.eSignedInOfficeStyle {
			display: inline-block;
			width: 136px;
			height: 23px;
			padding: 1px 0px;
			text-align: center;
			border-radius: 4px;
			border: 1px solid #47682d;
			background-color: #f0f4ec;
			color: #47682d;
			font-family: Mulish;
			font-size: 14px;
			font-weight: 400;
			line-height: 21px;
		}

		.signedAndESignedStyle {
			display: inline-block;
			width: 150px;
			height: 23px;
			padding: 1px 0px;
			text-align: center;
			border-radius: 4px;
			border: 1px solid #47682d;
			background-color: #f0f4ec;
			color: #47682d;
			font-family: Mulish;
			font-size: 14px;
			font-weight: 400;
			line-height: 21px;
		}

		.reviewedStyle {
			display: inline-block;
			width: 80px;
			height: 23px;
			padding: 1px 0px;
			text-align: center;
			border-radius: 4px;
			border: 1px solid #47682d;
			background-color: #f0f4ec;
			color: #47682d;
			font-family: Mulish;
			font-size: 14px;
			font-weight: 400;
			line-height: 21px;
		}

		.partiallyReviewedStyle {
			display: inline-block;
			width: auto;
			height: 23px;
			padding: 0px 8px;
			text-align: center;
			border-radius: 4px;
			border: 1px solid #a9570f;
			background-color: #fbf0e7;
			color: #a9570f;
			font-family: Mulish;
			font-size: 14px;
			font-weight: 400;
			line-height: 21px;
		}

		.completedStyle {
			display: inline-block;
			width: auto;
			height: 23px;
			padding: 0px 8px;
			text-align: center;
			border-radius: 4px;
			border: 1px solid #47682d;
			background-color: #f0f4ec;
			color: #47682d;
			font-family: Mulish;
			font-size: 14px;
			font-weight: 400;
			line-height: 21px;
		}

		.actionsContainer {
			display: flex;
			align-items: center;
			justify-content: flex-start;
			gap: 0.5rem;
			color: #05386b;

			.verticalDivider {
				display: flex;
				align-items: center;
				svg {
					margin-top: 2px;
				}
			}
			.view {
				cursor: pointer;
			}
			.hide {
				display: none;
			}
			.spaceForEllipsis {
				button {
					margin-left: 58px;
				}
			}
			:global .btn-group-vertical {
				.documentMoreActions {
					#documentMoreActions,
					#sse-documentMoreActions {
						min-width: 0px;
						border: none;
						background-color: transparent;
						padding: 0;
					}

					.dropdown-menu {
						border: 1px solid #00000026;
						border-radius: 4px;
						padding: 8px 0px;
						font-family: Mulish;
						font-size: 16px;
						font-weight: 400;
						line-height: 24px;
						min-width: 140px;
						cursor: default;

						li {
							padding: 4px 1rem;
							line-height: 150%;
							cursor: pointer;
							white-space: nowrap;
						}

						li:hover {
							background-color: rgb(0, 0, 0, 0.075);
						}
					}
				}
			}
		}
	}

	.nameData {
		width: 45%;
	}
	.containsData {
		width: 15.5%;
	}
	.dateData {
		width: 13%;
		cursor: default;
	}
	.statusData {
		width: 18%;
	}

	.exipryAlarmIcon{
		padding-left: 1rem;

		& > svg:first-child {
			margin-top: -4px;
			margin-right: 0.5rem;
		}
	}
}

.nodeSelected {
	background-color: #e6f1f8;
}

:global .datePopover {
	overflow: hidden !important;
	font-family: Mulish !important;
	font-size: 14px !important;
	font-weight: 400 !important;
	line-height: 22px !important;
	min-width: 20px;
	border-radius: 2px !important;
	margin-top: 0rem;

	.popoverBody {
		height: 22px !important;
		background-color: #d9d9d9 !important;
		background: #d9d9d9 !important;
		color: #212529 !important;
		padding: 0 0.5rem;
		font-family: Mulish !important;
		font-size: 14px !important;
		font-weight: 400 !important;
		line-height: 22px !important;
	}
}
