.navigationContainer {
	width: 250px;
	min-width: 250px;
	background-color: #075c95;
	padding: 1.5rem 0;
	position: relative;

	.companyLogoContainer {
		padding: 0 25px;

		img {
			max-width: 200px;
			max-height: 100px;
		}
	}

	.menuItemsContainer {
		margin-top: 1.5rem;

		.menuRouteItems {
			padding: 0px;
			margin: 0px;

			.eachMenu {
				display: flex;
				align-items: center;
				height: 40px;
				padding-left: 1.2rem;
				font-family: Mulish;
				font-size: 16px;
				font-weight: 400;
				line-height: 24px;
				color: #fff;
				border-left: 3px solid #075c95;
				cursor: pointer;

				svg {
					margin-right: 1rem;
				}

				.hidePageName {
					display: none;
				}
			}

			.eachMenu:hover {
				background: #3a8fc880;
			}

			.selected {
				border-left: 3px solid #88c656;
				background: #3a8fc880;
				cursor: default;
				font-weight: 800;
			}
		}

		.bottomContainer {
			padding: 0 1rem 0 1.2rem;
			width: 250px;
			height: 40px;
			display: flex;
			align-items: center;
			justify-content: space-between;
			position: absolute;
			bottom: 1.5rem;

			.logoutContainer {
				display: flex;
				align-items: center;
				width: 100%;
				height: 100%;
				font-family: Mulish;
				font-size: 16px;
				font-weight: 400;
				line-height: 24px;
				color: #fff;
				cursor: pointer;

				svg {
					margin-right: 1rem;
				}

				.hidePageName {
					display: none;
				}
			}

			.logoutContainerCollapsed {
				width: auto;
				height: auto;

				svg {
					margin-right: 0px;
				}
			}

			.collapseContainer {
				display: flex;
				align-items: center;
				cursor: pointer;
			}
		}

		.bottomContainerCollapsed {
			padding: 0;
			height: auto;
			flex-direction: column;
			gap: 1.5rem;
		}
	}
}

.collapsedWidth {
	width: 62px !important;
	min-width: 62px !important;
}
