@import 'src/styles/variables';

.completed-tasks-modal {
	.modal-body {
		padding: 16px 25px 23px 25px;
		background-color: #fff;
		max-height: calc(100vh - 180px);
		overflow: hidden;
		overflow-y: scroll;
	}

	.modal-footer {
		background: #fff;

		.btn-primary:hover {
			background: $primary-blue-hover;
		}

		.btn-primary {
			background: $primary-blue;
			border: 1px solid $primary-blue;
			border-radius: 2px !important;
			color: #ffffff;
			font-size: 16px;
			font-weight: 400;
			line-height: 24px;
			width: 100px;
		}
	}
}
