.otpExpiryMessage {
	color: #cc4a43;
	font-weight: 600;
}

.otpContainer {
	padding: 1.8rem 0 1.3rem 0;
	margin-bottom: 16px;
}

.resendOTPContainer {
	font-family: Mulish;
	font-size: 14px;
	font-weight: 400;
	line-height: 24px;
	white-space: nowrap;
	color: #565a5e;

	span {
		font-weight: 700;
		text-decoration: underline;
		cursor: pointer;
		color: #212529;
	}
}

.confirmContainer {
	margin-top: 1.5rem;
	.confirm {
		width: 370px;
		height: 38px;
		padding: 6px 12px;
		border-radius: 2px;
		border: 1px solid #0973ba;
		background-color: #0973ba;
		font-family: Mulish;
		font-size: 16px;
		font-weight: 400;
		line-height: 24px;
		color: #fff;
		cursor: pointer;
	}
	.confirm:disabled {
		border: 1px solid #c4c6c8;
		background-color: #f0f1f1;
		color: #c4c6c8;
		cursor: not-allowed;
	}
}
