.breadcrumbs {
	.eachLevel {
		font-family: Mulish;
		font-size: 16px;
		font-weight: 400;
		line-height: 24px;
		color: #05386b;
		text-decoration: underline;
		cursor: pointer;
	}
	.lastLevel {
		font-family: Mulish;
		font-size: 16px;
		font-weight: 400;
		line-height: 24px;
		color: #0973ba;
		cursor: pointer;
	}
}
