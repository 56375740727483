.confirmPadding {
    padding-top: 1rem !important;
}

.pinContainer {
    display: flex;
    align-items: center;
    width: 370px;
    height: 38px;
    border-radius: 4px;
    border: 1px solid #898d91;

    .pin {
        width: 345px;
        height: 36px;
        border-radius: 4px;
        padding: 6px 12px;
        border: 0px !important;
        font-family: Mulish;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        color: #565a5e;
        outline: none;
    }

    .eyeContainer {
        cursor: pointer;
    }
}

.error {
    border: 1px solid #cc4a43 !important;
}

.errorContainer {
    font-family: Mulish;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    color: #cc4a43;
    margin-top: 0.6rem;
}

.resetPinContainer {
    margin-top: 1.8rem;

    .resetPin {
        width: 370px;
        height: 38px;
        padding: 6px 12px;
        border-radius: 2px;
        border: 1px solid #0973ba;
        background-color: #0973ba;
        font-family: Mulish;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        color: #fff;
        cursor: pointer;
    }

    .resetPin:disabled {
        border: 1px solid #c4c6c8;
        background-color: #f0f1f1;
        color: #c4c6c8;
        cursor: not-allowed;
    }
}
