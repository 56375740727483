@import 'src/styles/variables';

.completedTasksPagination {
	display: flex;
	justify-content: end;
	padding-bottom: 12px;
	margin-top: 16px;

	.showPageNumber {
		color: $brand-primary-blue-2;
		display: flex;
		align-items: center;
		padding: 0;
		margin-bottom: 0px;
		margin-right: 0.5rem;
		
		li {
			height: 31px;
			font-size: 14px;
			font-weight: 400;
			line-height: 21px;
			list-style: none;
			padding: 6px 6px;
			border: 1px solid $bg-white;
			display: flex;
			align-items: center;
			margin-right: 1px;
			cursor: pointer;
			font-weight: bold;

			svg {
				width: 12px;
			}

			span {
				width: 11px;
				height: 11px;
				display: flex;
				align-items: center;
				justify-content: center;
			}
		}

		li.currentPageNumber {
			background-color: $brand-primary-blue-2;
			border: 1px solid $brand-primary-blue-2;
			color: $white;
			font-weight: normal;
			padding: 1px 9px;
			cursor: default;
		}

		li.paginationActionDisabled {
			color: $gray;
			background-color: $bg-white;
			cursor: not-allowed;
		}
	}
}

@media (max-width: 500px) {
	.completedTasksPagination {
		display: flex;
		justify-content: space-between;
		flex-direction: column;
		align-items: center;

		.showPageNumber {
			margin-bottom: 9px;
			margin-right: 0px;
		}
	}
}
