@import 'src/styles/variables';

.dashboard {
	display: flex;
	flex-direction: column;
	height: 100%;
	font-family: $font-family;
	padding: 2rem 1.5rem 0 1.5rem;
	overflow: hidden;

	.topSection {
		width: 100%;
		height: 124px;
		display: flex;
		justify-content: space-between;

		.lastLoginDate {
			display: inline-block;
			padding: 2px 16px;
			background-color: #f0f1f1;
			border-radius: 25px;
			color: $brand-primary-blue-1;
			font-size: 12px;
			font-weight: 400;
			line-height: 16px;
		}

		.greeting {
			font-size: 30px;
			font-weight: 300;
			line-height: 24px;
			margin-top: 32px;

			.greetingName {
				color: $brand-primary-blue-2;
			}
		}

		.increaseMargin {
			margin-top: 52px !important;
		}

		.notification {
			position: relative;

			.notificationIcon {
				margin-top: 13px;
				cursor: pointer;
			}

			.notificationDot {
				width: 8px;
				height: 8px;
				border-radius: 50%;
				background-color: #cc4a43;
				position: absolute;
				top: 12px;
				right: 5px;
			}
		}
	}

	.mainContent {
		display: flex;
		width: 100%;
		height: calc(100vh - 186px);

		.leftContainer {
			width: 65%;
			height: 100%;
			overflow: hidden;
			overflow-y: scroll;
			padding-right: 48px;
			border-right: 1px solid #00000020;
			scroll-behavior: smooth;
			.header {
				font-size: 18px;
				font-weight: 700;
				line-height: 22.59px;
			}

			.cardsContainer {
				display: flex;
				flex-wrap: nowrap;
				justify-content: space-between;
				margin-top: 16px;

				.card {
					width: 45%;
					height: 106px;
					background-color: #ddd8c41a;
					border-radius: 4px;
					padding: 0 28px;
					display: flex;
					align-items: center;

					&:nth-child(1) {
						border-left: 5px solid #d36d13;

						.count {
							color: #d36d13;
						}
					}

					&:nth-child(2) {
						border-left: 5px solid #5c8001;

						.count {
							color: #5c8001;
						}
					}

					.count {
						font-size: 63px;
						font-weight: 200;
						line-height: 81.9px;
						margin: 0 21px;
						font-family: 'Inter', sans-serif;
					}

					.taskCategoryName {
						font-size: 14px;
						font-weight: 500;
						line-height: 17.57px;
					}
				}
			}

			.actionCategories {
				.actionCategoryHeader {
					font-size: 18px;
					font-weight: 700;
					line-height: 22.59px;
					margin-top: 40px;
					margin-bottom: 23px;
				}

				.actionSubCategory {
					.actionSubCategoryHeader {
						font-size: 14px;
						font-weight: 700;
						line-height: 17.57px;
						display: flex;
						align-items: center;
						margin-bottom: 5px;

						.count {
							width: 20px;
							height: 20px;
							border-radius: 50%;
							background-color: #d36d13;
							color: $white;
							font-size: 10px;
							font-weight: 800;
							line-height: 15px;
							display: flex;
							justify-content: center;
							align-items: center;
							margin-left: 6px;
						}
					}

					.actionSubCategoryTable {
						:global .table > :not(caption) > * > * {
							padding: 0;
							box-shadow: none;
						}

						.customTableStyles {
							width: 100%;
							table-layout: fixed;
							margin-bottom: 8px;

							tr {
								display: flex;
								align-items: center;
								justify-content: space-between;
								height: 55px;
								border-bottom: 0.5px solid #c4c6c8;
								background-color: #ffffff;

								&:last-child {
									border-bottom: none;
								}

								td {
									border: none;
									font-size: 14px;
									font-weight: 500;
									line-height: 21px;
								}
							}

							.pendingTasksBtn {
								min-height: 31px;
								min-width: 92px;
							}

							.fixedWidth {
								width: 93px;
							}

							.ellipsisText {
								width: calc(100% - 100px);
								overflow: hidden;
								text-overflow: ellipsis;
								white-space: nowrap;
								display: flex;
								align-items: center;

								.text {
									margin-right: 4px;

									&.ellipsis {
										overflow: hidden;
										text-overflow: ellipsis;
										white-space: nowrap;
									}
								}

								.mfjWrapper {
									width: 24px;
									height: 16px;
									display: flex;
									align-items: center;
									justify-content: center;
								}

								.mfjIcon {
									margin: 0 4px;
									cursor: pointer;
								}

								.pillShapeDelegation {
									height: 20px;
									padding: 0 10px;
									border-radius: 50px;
									text-align: center;
									border: 1px solid #355abf;
									color: #355abf;
									display: flex;
									align-items: center;
									font-size: 13px;
									font-weight: 700;
									line-height: 19.5px;
									margin: 0 4px;

									.delegatedIcon {
										margin-right: 5px;
									}
								}

								.pillShapeReminder {
									height: 20px;
									padding: 0 10px;
									border-radius: 50px;
									text-align: center;
									border: 1px solid #ae1b6a;
									color: #ae1b6a;
									display: flex;
									align-items: center;
									font-size: 13px;
									font-weight: 700;
									line-height: 19.5px;
									margin: 0 4px;

									.dueSoon {
										margin-right: 5px;
									}
								}
							}
						}

						.seeMore {
							display: inline-block;
							font-size: 14px;
							font-weight: 400;
							line-height: 17.57px;
							color: $brand-primary-blue-2;
							text-decoration: underline;
							cursor: pointer;
							margin-bottom: 45px;
						}
						.backToTopContainer {
							margin-top: -4px;
							display: inline-block;
							float: right;
						}
						.backToTop {
							display: inline-block;
							font-size: 14px;
							font-weight: 400;
							line-height: 17.57px;
							color: $brand-primary-blue-2;
							text-decoration: underline;
							cursor: pointer;
							margin-bottom: 45px;
						}
						.upperArrow {
							color: $brand-primary-blue-2;
							font-size: 20px;
							margin-left: 5px;
						}
					}
				}
			}
		}

		.rightContainer {
			width: 35%;
			height: 100%;
			padding: 0 1.2rem 1rem;
			display: flex;
			flex-direction: column;
			gap: 1.5rem;
			overflow-y: auto;

			.header {
				font-size: 18px;
				font-weight: 700;
				line-height: 22.59px;
			}
		}
	}
}

.collapsedWidth {
	width: calc(100% - 62px);
}

.expandWidth {
	width: calc(100% - 250px);
}

.cursorPointer {
	cursor: pointer;
}
