@import 'src/styles/variables';

.email-update-popup-modal .modal-dialog {
	display: flex;
	width: 641px;
	max-width: 641px;
	max-height: calc(100% - 3.5rem);
	height: 320px;

	.modal-content {
		border-radius: 4.8px;
		box-sizing: border-box;
		border: none;
		height: fit-content;

		.modal-header {
			background: #e6f1f8;
			border-bottom: 1px solid #dee2e6;
			line-height: 16px;
			font-size: 14px;
			padding: 9px 1rem;
			border-top-left-radius: 4.8px;
			border-top-right-radius: 4.8px;

			.btn-close {
				color: #99bde1;
				width: 16px;
				height: 16px;
				line-height: 16px;
				cursor: pointer;
			}

			.modal-title {
				line-height: 20px;
				font-size: 18px;
				color: #212529;
				font-family: Mulish;
			}
		}

		.modal-body {
			padding-top: 0px;
			padding-bottom: 40px;
			.oldEmail,
			.newEmail {
				width: 440px;
				height: 31px;
				border-radius: 4px;
				padding: 4px 12px;
				border: 1px solid #898d91;
				font-family: Mulish;
				font-size: 16px;
				font-weight: 400;
				line-height: 24px;
				color: #565a5e;
				outline: none;
			}

			.oldEmail:disabled {
				background-color: #f0f1f1;
				border: 1px solid #c4c6c8;
				color: #c4c6c8;
			}

			.error {
				border: 1px solid #cc4a43 !important;
			}

			.errorContainer {
				font-family: Mulish;
				font-size: 14px;
				font-weight: 400;
				line-height: 21px;
				color: #cc4a43;
				margin-top: 0.6rem;
			}
			.col-lg-12 {
				padding-left: 26px;
			}

			.otpVerification {
				div:first-of-type {
					margin-top: 20px;
					font-family: Mulish;
					color: #212529;
				}
			}

			.verifyMainContainer {
				padding-top: 20px;
			}

			.veryEmailContainer {
				max-width: 674px;
				min-width: 440px;
				border: 1px solid #898d91;
				border-radius: 4px;
				border-left: 4px solid #0973ba;
				padding: 0.5rem 1rem;
				position: relative;

				.veryEmailHeader {
					display: flex;
					justify-content: space-between;
					align-items: flex-start;

					.headerTitle {
						font-family: Mulish;
						font-size: 16px;
						font-weight: 700;
						line-height: 24px;
						color: #212529;
					}
				}

				.verifyDescription {
					font-family: Mulish;
					font-size: 16px;
					font-weight: 400;
					line-height: 24px;

					span {
						font-weight: 700;
					}
				}

				.codeContainer {
					margin-top: 1rem;
					display: flex;
					align-items: center;
				}
				.verifyCodeInput {
					width: 138px;
					height: 31px;
					padding: 4px 8px;
					border-radius: 3.2px;
					border: 1px solid #898d91;
					font-family: Mulish;
					font-size: 14px;
					font-weight: 400;
					line-height: 21px;
					color: #565a5e;
					outline: none;
				}

				.errorBorder {
					border: 1px solid #cc4a43;
				}

				.verifyCodeButton {
					margin-left: 0.7rem;
					width: 93px;
					height: 31px;
					padding: 4px 8px;
					border-radius: 2px;
					border: 1px solid #0973ba;
					background-color: #0973ba;
					font-family: Mulish;
					font-size: 14px;
					font-weight: 400;
					line-height: 21px;
					color: #fff;
					cursor: pointer;
				}

				.verifyCodeButton:disabled {
					background-color: #f0f1f1 !important;
					border: 1px solid #c4c6c8 !important;
					color: #c4c6c8 !important;
					cursor: not-allowed;
				}

				.codeError {
					font-family: Mulish;
					font-size: 14px;
					font-weight: 400;
					line-height: 21px;
					color: #cc4a43;
					margin-top: 3px;
				}

				.resend {
					bottom: 1.5rem;
					font-family: Mulish;
					font-size: 14px;
					font-weight: 400;
					line-height: 21px;
					color: #212529;
					margin-top: 20px;
					margin-bottom: 20px;
					span {
						font-weight: 700;
						color: #0973ba;
						cursor: pointer;
					}
				}
			}
		}

		.modal-footer {
			background: #fff;

			.btn-default {
				background: #f0f1f1;
				border: 1px solid $input-border-color;
				border-radius: 2px;
				color: #202223;
				font-size: 1rem;
				width: 125px;
				height: 38px;
			}

			.btn-default:hover {
				background: #d6dada;
			}

			.btn-primary:hover {
				background: $primary-blue-hover;
			}
			.btn-primary {
				background: $primary-blue;
				border: 1px solid $primary-blue;
				border-radius: 2px;
				color: #ffffff;
				font-size: 1rem;
				width: 125px;
				height: 38px;
			}
			.btn-primary:disabled {
				border: 1px solid #c4c6c8;
				background-color: #f0f1f1;
				color: #c4c6c8;
				cursor: not-allowed;
			}

			.btn-back {
				width: 112px;
				height: 38px;
				padding: 6px 12px 6px 12px;
				border-radius: 2px;
				border: 1px solid #0973ba;
				color: #0973ba;
				background-color: #ffffff;
				margin-top: 5px;
				position: absolute;
				left: 10px;
			}

			.btn-back:hover {
				background: #eee;
			}
		}
	}
}
