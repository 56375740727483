@import 'src/styles/variables';

.logged-out-modal .modal-dialog {
    display: flex;
    width: 500px;
    max-width: 500px;
    max-height: calc(100% - 3.5rem);

    .modal-content {
        border-radius: 4.8px;
        box-sizing: border-box;
        border: none;       

        .modal-header {
            background: #e6f1f8;
            border-bottom: 1px solid #DEE2E6;
            line-height: 16px;
            font-size: 14px;
            padding: 9px 1rem;
            border-top-left-radius: 4.8px;
            border-top-right-radius: 4.8px;

            .btn-close {
                color: #99bde1;
                width: 16px;
                height: 16px;
                line-height: 16px;
                cursor: pointer;
            }

            .modal-title {
                line-height: 20px;
                font-size: 18px;
                color: #212529;                
            }
        }

        .modal-body {
        }

        .modal-footer {
            background: #fff; 
            
            .btn-primary:hover{
                background:$primary-blue-hover;
            }
            .btn-primary {
                background: $primary-blue;
                border: 1px solid $primary-blue;
                border-radius: 2px;
                color: #FFFFFF;
                font-size: 1rem;
                width: 125px;
            }
        }
    }        
}