.emailID {
	width: 370px;
	height: 38px;
	border-radius: 4px;
	border: 1px solid #898d91;
	padding: 6px 12px;
	font-family: Mulish;
	font-size: 16px;
	font-weight: 400;
	line-height: 24px;
	color: #212529;
	outline: none;
}

.emailID::placeholder {
	color: #565a5e;
}

.emailID:disabled {
	border: 1px solid #c4c6c8;
	background: #f0f1f1;
	color: #c4c6c8;
	cursor: not-allowed;
}

.newEmailError {
	border: 1px solid #cc4a43 !important;
}

.continueContainer {
	margin-top: 1.6rem;
	.continue {
		width: 370px;
		height: 38px;
		padding: 6px 12px;
		border-radius: 2px;
		border: 1px solid #0973ba;
		background-color: #0973ba;
		font-family: Mulish;
		font-size: 16px;
		font-weight: 400;
		line-height: 24px;
		color: #fff;
		cursor: pointer;
	}
	.continue:disabled {
		border: 1px solid #c4c6c8;
		background-color: #f0f1f1;
		color: #c4c6c8;
		cursor: not-allowed;
	}
}
