.layout {
	width: 100vw;
	height: 100vh;
	display: flex;

	.leftContainer {
		width: 56%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: space-between;
		gap: 3rem;

		.outerScrollContainer {
			padding-top: 16vh;
			width: 100%;
			height: calc(100vh - 6rem);
			overflow-y: auto;
			scrollbar-width: thin;
			scrollbar-color: #075c95 #fff;

			.innerContainer {
				width: 78%;
				padding-left: 22%;

				.companyLogo {
					img {
						max-width: 240px;
						max-height: 100px;
					}
				}

				.content {
					padding-top: 2rem;
				}
			}
		}

		.outerScrollContainer::-webkit-scrollbar:vertical {
			width: 0.3rem;
		}

		@media only screen and (max-width: 1380px) {
			.outerScrollContainer {
				padding-top: 12.5vh;
			}
		}

		.footerContainer {
			height: 3rem;
			width: 100%;
			display: flex;
			justify-content: center;
			gap: 8px;
			font-family: Mulish;
			font-size: 14px;
			font-weight: 400;
			line-height: 21px;
			color: #6b7075;

			a {
				color: #6b7075;
			}
		}
	}

	.rightContainer {
		width: 44%;
		background-size: auto 100vh;
		background-repeat: no-repeat;
		background-color: #075c95;
		background-position-x: right;
	}
}
