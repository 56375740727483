.k1Container {
	margin-top: 1.5rem;

	table {
		width: 100%;
		font-family: Mulish;
		font-size: 16px;
		font-weight: 400;
		line-height: 24px;
		color: #212529;
		table-layout: fixed;

		thead {
			border-bottom: 2px solid #212529;

			th {
				font-family: Mulish;
				font-size: 16px;
				font-weight: 400;
				line-height: 24px;
				color: #212529;
				padding-bottom: 4px;
				vertical-align: bottom;
			}

			.nameHeader {
				width: 68%;
				padding-left: 8px;
			}
			.statusHeader {
				width: 20%;
				min-width: 105px;
			}
		}

		tbody {
			.k1detailRow {
				height: 41px;
				vertical-align: middle;
				font-family: Mulish;
				font-size: 16px;
				font-weight: 400;
				line-height: 24px;
				border-bottom: 1px solid #a6a9ac;

				td {
					vertical-align: middle;
				}

				.nameData {
					width: 68%;
					overflow: hidden;
					white-space: nowrap;
					text-overflow: ellipsis;
					padding: 0 8px;
					color: #212529;
				}
				.statusData {
					width: 20%;
					min-width: 105px;

					span {
						font-family: Mulish;
						font-size: 14px;
						font-weight: 400;
						line-height: 21px;
					}

					.notSent {
						width: 73px;
						height: 23px;
						padding: 1px 8px;
						border-radius: 4px;
						border: 1px solid #8b1655;
						background-color: #f7e8f0;
						color: #8b1655;
					}

					.partiallySent {
						width: 103px;
						height: 23px;
						padding: 1px 8px;
						border-radius: 4px;
						border: 1px solid #a9570f;
						background-color: #fbf0e7;
						color: #a9570f;
					}

					.sent {
						width: 46px;
						height: 23px;
						padding: 1px 8px;
						border-radius: 4px;
						border: 1px solid #47682d;
						background-color: #f0f4ec;
						color: #47682d;
					}
				}
				.actionData {
					color: #05386b;

					span {
						cursor: pointer;
					}
				}
			}
		}
	}
}
