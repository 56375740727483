.filterContainer {
	position: relative;
	padding: 0 1rem 5rem;
	height: calc(100vh - 91px);

	.filterOptions {
		border-top: 1px solid #21252940;
		height: 100%;
		overflow-y: auto;
		overflow-x: hidden;

		:global .accordion {
			.accordion-item {
				border-bottom: 1px solid #21252940;
				.accordion-header {
					button {
						padding: 0.6rem 3px;
						background: #fff;
						box-shadow: none;

						.itemHeader {
							width: 100%;
							padding-right: 0.5rem;
							display: flex;
							justify-content: space-between;

							.itemHeaderText {
								font-family: Mulish;
								font-size: 16px;
								font-weight: 700;
								line-height: 24px;
								color: #212529;
							}
							.itemHeaderCount {
								display: inline-flex;
								align-items: center;
								justify-content: center;
								width: 24px;
								height: 24px;
								border-radius: 50%;
								background-color: #e6ebf0;
								font-family: Mulish;
								font-size: 14px;
								font-weight: 400;
								line-height: 21px;
								color: #212529;
							}
						}
					}

					.accordion-button:not(.collapsed)::after {
						background-image: url('../../../assets/svg/minus-icon.svg');
					}

					.accordion-button::after {
						content: '';
						background-image: url('../../../assets/svg/plus-icon.svg');
						background-repeat: no-repeat;
					}
				}

				.accordion-collapse {
					.accordion-body {
						padding: 0px 3px 0.5rem;

						.dateContainer {
							display: flex;
							justify-content: space-between;
							padding-bottom: 0.6rem;

							.fromContainer,
							.toContainer {
								label {
									font-family: Mulish;
									font-size: 14px;
									font-weight: 600;
									line-height: 21px;
									color: #212529;
									margin-bottom: 3px;
								}
							}

							.fromDate,
							.toDate {
								width: 144px;
								height: 31px;
								border: 1px solid #898d91;
								border-radius: 3.2px;
								box-shadow: none;
								padding: 5px 9px;

								.ant-picker-input {
									input {
										font-family: Mulish;
										font-size: 14px;
										font-weight: 400;
										line-height: 21px;
										color: #212529;
									}
								}
							}

							.fromDate:hover {
								.ant-picker-suffix {
									display: block !important;

									svg {
										display: block !important;
									}
								}
							}
						}

						.statusContainer {
							height: 94px;
							overflow-y: auto;
							padding-top: 0.3rem;
							margin-bottom: 0.5rem;

							div {
								display: flex;
								align-items: center;
								gap: 0.5rem;
								margin-bottom: 0.6rem;

								input,
								label {
									margin: 0px;
									cursor: pointer;
								}

								input {
									height: 14px;
									width: 14px;
								}

								input[type='checkbox']:checked {
									accent-color: #0973ba !important;
									color: #ffffff !important;
								}
							}

							div:last-child {
								margin-bottom: 0;
							}
						}

						.fileTypeContainer {
							height: 94px;
							overflow-y: auto;
							padding-top: 0.3rem;
							margin-bottom: 0.5rem;

							div {
								display: flex;
								align-items: center;
								gap: 0.5rem;
								margin-bottom: 0.6rem;

								input,
								label {
									margin: 0px;
									cursor: pointer;
								}

								input {
									height: 14px;
									width: 14px;
								}

								input[type='checkbox']:checked {
									accent-color: #0973ba !important;
									color: #ffffff !important;
								}
							}

							div:last-child {
								margin-bottom: 0;
							}
						}
					}
				}
			}
		}
	}

	.footerButtons {
		position: absolute;
		width: 100%;
		left: 0px;
		bottom: 1rem;
		padding: 0 1rem;
		display: flex;
		justify-content: space-between;

		.clearAll {
			width: 88px;
			height: 38px;
			padding: 6px 12px;
			border: 0px;
			font-family: Mulish;
			font-size: 16px;
			font-weight: 400;
			line-height: 24px;
			color: #0973ba;
			background-color: #ffffff;
			cursor: pointer;
		}

		.apply {
			width: 100px;
			height: 38px;
			padding: 6px 12px;
			border-radius: 2px;
			border: 1px solid #0973ba;
			background-color: #0973ba;
			font-family: Mulish;
			font-size: 16px;
			font-weight: 400;
			line-height: 24px;
			color: #ffffff;
			cursor: pointer;
		}
	}
}
:global .fromDatePopup,
:global .toDatePopup {
	z-index: 2000;
	top: 184px !important;

	.ant-picker-panel-container {
		border-radius: 4px;

		.ant-picker-panel {
			.ant-picker-header {
				border-bottom: 1px solid #dee2e6;
				color: #212529;

				.ant-picker-month-btn,
				.ant-picker-year-btn {
					font-family: Mulish;
					font-size: 14px;
					font-weight: 400;
					line-height: 21px;
				}

				.ant-picker-month-btn:hover,
				.ant-picker-year-btn:hover {
					color: #0973ba;
				}
			}

			.ant-picker-body {
				font-family: Mulish;
				font-size: 14px;
				font-weight: 400;
				line-height: 21px;
				color: #212529;

				.ant-picker-cell {
					color: #6b7075;
				}

				.ant-picker-cell-in-view {
					color: #212529;
				}

				.ant-picker-cell-selected .ant-picker-cell-inner {
					border-radius: 2px;
					background: #0973ba !important;
				}

				.ant-picker-cell-selected .ant-picker-cell-inner::before {
					border: 1px solid #0973ba;
					border-radius: 2px;
				}

				.ant-picker-cell-today .ant-picker-cell-inner::before {
					border: 1px solid #0973ba;
					border-radius: 2px;
				}
			}
		}

		.ant-picker-footer {
			border-top: 1px solid #dee2e6;

			.ant-picker-now-btn {
				font-family: Mulish;
				font-size: 14px;
				font-weight: 400;
				line-height: 21px;
				color: #0973ba;
				text-decoration: none;
			}
		}
	}
}

:global .fromDatePopup:before {
	content: url('../../../assets/svg/arrow-head.svg');
	position: absolute;
	left: 18px;
	top: -15px;
	margin: 0 auto;
	width: 0;
	height: 0;
}

:global .toDatePopup:before {
	content: url('../../../assets/svg/arrow-head.svg');
	position: absolute;
	right: 33px;
	top: -15px;
	margin: 0 auto;
	width: 0;
	height: 0;
}
