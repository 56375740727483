.emailContainer {
	display: flex;
	align-items: center;
	width: 370px;
	height: 38px;
	border-radius: 4px;
	border: 1px solid #898d91;

	.emailID {
		width: 345px;
		height: 36px;
		border-radius: 4px;
		padding: 6px 12px;
		border: 0px !important;
		font-family: Mulish;
		font-size: 16px;
		font-weight: 400;
		line-height: 24px;
		color: #565a5e;
		outline: none;
	}

	.infoIconContainer {
		cursor: pointer;
	}
}

.userName {
	width: 370px;
	height: 38px;
	border-radius: 4px;
	padding: 6px 12px;
	border: 1px solid #898d91;
	font-family: Mulish;
	font-size: 16px;
	font-weight: 400;
	line-height: 24px;
	color: #565a5e;
	outline: none;
}

.errorIconContainer {
	margin-top: -13px;
}

.continueContainer {
	margin-top: 1.6rem;
	.continue {
		width: 370px;
		height: 38px;
		padding: 6px 12px;
		border-radius: 2px;
		border: 1px solid #0973ba;
		background-color: #0973ba;
		font-family: Mulish;
		font-size: 16px;
		font-weight: 400;
		line-height: 24px;
		color: #fff;
		cursor: pointer;
	}
	.continue:disabled {
		border: 1px solid #c4c6c8;
		background-color: #f0f1f1;
		color: #c4c6c8;
		cursor: not-allowed;
	}
}

.error {
	border: 1px solid #cc4a43 !important;
}

.errorContainer {
	font-family: Mulish;
	font-size: 14px;
	font-weight: 400;
	line-height: 21px;
	color: #cc4a43;
	margin-top: 0.6rem;
}

.registrationContainer {
	margin-top: 12px;

	.text {
		font-family: Mulish;
		font-size: 16px;
		font-weight: 400;
		line-height: 24px;
		color: #565a5e;
	}

	.signUp {
		font-family: Mulish;
		font-size: 16px;
		font-weight: 700;
		line-height: 24px;
		color: #05386b;
		cursor: pointer;
	}
}
.switchSettingsIcon {
	margin-left: 4px;
	svg {
		cursor: pointer;
		height: 14px;
	}
}

.optionalText {
	font-size: 0.75rem;
	color: #6b7075;
	margin-top: 4px;
	align-self: flex-end;
	position: absolute;
	left: 320px;
}

.headerContainer {
	display: flex;
	align-items: center;
	position: relative;
}

:global .clientHubTooltip {
	.tooltip-inner {
		max-width: 360px;
		text-align: left;
		padding: 4px 8px;
		border-radius: 4px;
		font-family: 'Mulish', sans-serif;
		font-size: 14px;
		font-weight: 400;
		line-height: 21px;
		margin-top: -6px;
	}
}

:global .inputMobileNo {
	border-radius: 0px 2px 2px 0px;
	margin-bottom: 0.5rem;

	.form-control {
		height: 36px;
		display: flex;
		align-items: center;
		width: 370px;
		border-radius: 4px !important;
		border: 1px solid #898d91;
		&:focus {
			outline: none;
			box-shadow: none;
			border: 1px solid #898d91;
		}
	}

	.flag-dropdown ul {
		width: 250px;
	}
}

.phoneNumber {
	:global .flag-dropdown {
		border: 1px solid #898d91 !important;
	}
}
