@import 'src/styles/variables';
@import '~bootstrap/scss/bootstrap';
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@100;200;300;400;500;600;700&display=swap');


* {
	margin: 0px;
	padding: 0px;
	box-sizing: border-box;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
	-webkit-box-shadow: 0 0 0px 1000px white inset;
	box-shadow: 0 0 0px 1000px white inset;
	background-color: white;
}

/*-------- Scroll Bar CSS---Start-- */
/* width */
::-webkit-scrollbar {
	width: 8px;
	background-color: inherit;
}

/* Track */
::-webkit-scrollbar-track {
	box-shadow: inset 0 0 5px $white;
	border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: $brand-primary-blue-2;
	border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: $brand-primary-blue-2;
}

::-webkit-scrollbar:horizontal {
	height: 8px;
	background: #ced4da;
}
::-webkit-scrollbar-thumb:horizontal {
	background: $brand-primary-blue-2;
	border-radius: 10px;
}
/*-------- Scroll Bar CSS---End---- */

.uteLoginHeader {
	font-family: Mulish;
	font-size: 32px;
	font-weight: 300;
	line-height: 38.4px;
	color: #212529;
}

.uteLoginSubHeader {
	font-family: Mulish;
	font-size: 16px;
	font-weight: 400;
	line-height: 24px;
	color: #565a5e;
	margin-top: 10px;
	white-space: nowrap;
}

.formInputHeader {
	font-family: Mulish;
	font-size: 14px;
	font-weight: 600;
	line-height: 21px;
	color: #212529;
	padding-top: 1.6rem;
	margin-bottom: 6px;
}

.formInputForgotText {
	font-family: Mulish;
	font-size: 14px;
	font-weight: 400;
	line-height: 21px;
	color: #05386b;
	text-decoration: underline;
	cursor: pointer;
}

.pageMainContent {
	padding: 2rem 1.5rem 0 1.5rem;
	display: flex;
	flex-direction: column;
	flex: 1;
	overflow-y: auto;

	.pageMainHeader {
		font-family: Mulish;
		font-size: 24px;
		font-weight: 500;
		line-height: 28.8px;
		margin: 0;
		color: #212529;
	}

	.pageModuleContainer {
		padding: 2rem 0;

		.pageModuleHeader {
			font-family: Mulish;
			font-size: 18px;
			font-weight: 600;
			line-height: 22.59px;
			margin-bottom: 0.6rem;
			color: #0973ba;
		}

		.pageModuleDescription {
			font-family: Mulish;
			font-size: 14px;
			font-weight: 400;
			line-height: 21px;
			margin: 0;
			color: #565a5e;
		}
	}

	hr {
		margin: 0px;
		border-top: 0.25px solid #6b7075;
	}
}

._loading_overlay_wrapper {
	._loading_overlay_overlay {
		background: rgba(0, 0, 0, 0.05);

		._loading_overlay_content {
			display: flex;
			flex-direction: column;
			align-items: center;
			gap: 0.5rem;
			color: #0973ba;
			font-family: Mulish;
			font-size: 16px;
			font-weight: 600;

			.loadingSpinnerColor {
				color: #0973ba;
				border-width: 2px;
			}
		}
	}
}

// removing extra eye icon for Edge
input[type='password']::-ms-clear,
::-ms-reveal {
	display: none;
}
