@import 'src/styles/variables';

.completedTasksListContainer {
	table {
		width: 100%;
		font-family: Mulish;
		font-size: 16px;
		font-weight: 400;
		line-height: 24px;
		color: $black;
		table-layout: fixed;

		thead {
			border-bottom: 2px solid $black;

			th {
				font-family: Mulish;
				font-size: 16px;
				font-weight: 600;
				line-height: 24px;
				color: $black;
				padding: 8px 8px 9px 8px;
			}

			.nameHeader {
				width: 70%;
			}

			.dateHeader {
				width: 30%;
			}
		}

		tbody {
			tr:nth-child(odd) {
				background-color: rgba(0, 0, 0, 0.05);
			}

			tr:nth-child(even) {
				background-color: #ffffff;
			}
		}
	}
}

.completedTasksListRow {
	height: 41px;
	font-family: Mulish;
	font-size: 16px;
	font-weight: 400;
	line-height: 24px;
	border-bottom: 1px solid #a6a9ac;

	&:last-child {
		border-bottom: none;
	}

	td {
		padding: 0 8px;

		.nameContainer {
			width: 100%;
			display: block;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
		}

		.dateContainer {
			display: flex;
			align-items: center;
			color: $brand-primary-blue-1;
		}
	}
}
