@import 'src/styles/variables.scss';

.sendFilesModule {
	.headerSendFiles {
		font-family: $font-family;
		font-size: 18px;
		font-weight: 700;
		line-height: 22.59px;
		text-align: left;
		color: #212529;
	}

	.body {
		margin-top: 2%;

		.bodyTitleContainer {
			font-family: $font-family;
			font-size: 14px;
			font-weight: 600;
			line-height: 21px;
			text-align: left;
			color: #6b7075;
			cursor: pointer;
		}
	}
}
