.radioBlock {
	display: flex;
	align-items: center;
	gap: 0.5rem;
	margin-top: 0.7rem;

	input {
		width: 16px;
		height: 16px;
		accent-color: #0973ba;
	}

	label {
		margin-bottom: 0px !important;
		font-family: Mulish;
		font-size: 16px;
		font-weight: 400;
		line-height: 24px;
		color: #212529;
	}
}

.emailID {
	width: 370px;
	height: 38px;
	border-radius: 4px;
	border: 1px solid #898d91;
	padding: 6px 12px;
	font-family: Mulish;
	font-size: 16px;
	font-weight: 400;
	line-height: 24px;
	color: #212529;
	outline: none;
}

.emailID::placeholder {
	color: #565a5e;
}

.countryCodeMobileNumberWrapper {
	position: relative;
	height: 38px;
	width: 370px;
	border: 1px solid #898d91;
	border-radius: 4px;

	.phoneInput {
		margin-bottom: 3px;

		input {
			height: 36px;
			width: 365px;
			padding-left: 62px;
			border: none !important;
			outline: none !important;
			border-radius: 4px !important;
			font-family: Mulish;
			font-size: 16px;
			font-weight: 400;
			line-height: 24px;
			text-align: left;
			color: #212529;

			&:focus {
				box-shadow: none;
			}
		}

		:global .flag-dropdown {
			height: 36px;
			width: 52px;
			border: 0px solid #898d91;
			border-right: 1px solid #898d91;
			background-color: #f0f1f1;

			.selected-flag {
				width: 50px;
			}

			.arrow {
				border: solid #212529;
				border-width: 0 1px 1px 0;
				height: 6px;
				margin-left: 6px;
				margin-top: 1px;
				top: 0;
				transform: rotate(45deg);
				-webkit-transform: rotate(45deg);
				width: 6px;
			}
		}
	}

	.mobileNumberInput {
		position: absolute;
		font-family: sans-serif, 'Poppins';
		height: 31px;
		width: 168px;
		border: 0px solid #898d91;
		color: #212529;
		font-size: 14px;
		font-weight: 400;
		padding: 4px 8px;
		left: 53px;
		top: 0px;
		outline: none;
	}
}

.continueContainer {
	margin-top: 1.6rem;
	.continue {
		width: 370px;
		height: 38px;
		padding: 6px 12px;
		border-radius: 2px;
		border: 1px solid #0973ba;
		background-color: #0973ba;
		font-family: Mulish;
		font-size: 16px;
		font-weight: 400;
		line-height: 24px;
		color: #fff;
		cursor: pointer;
	}
	.continue:disabled {
		border: 1px solid #c4c6c8;
		background-color: #f0f1f1;
		color: #c4c6c8;
		cursor: not-allowed;
	}
}
