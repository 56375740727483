@import 'src/styles/variables';

#updateEmailSection {
    div{
        padding: 5px 0px;
    }

    .btnDefault {
        width: 112px;
        height: 38px;
        padding: 6px 12px 6px 12px;
        border-radius: 2px;
        border: 1px solid #0973BA;        
        color: #0973BA;
        background-color: #ffffff;
        margin-top: 5px;
    }

    .btnDefault:hover{
        background: #eee;
    }

    .emailText{       
        font-family: Mulish;
        font-size: 14px;
        font-weight: 700;
        line-height: 21px;
        text-align: left;
        color: #565A5E;
    }
}