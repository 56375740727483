tbody {
	.messageRow {
		font-family: Mulish;
		font-size: 16px;
		font-weight: 500;
		line-height: 24px;
		text-align: center;
		height: 5rem;
	}
}
