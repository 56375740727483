@import 'src/styles/variables';

.filterListContainer {
	padding-top: 0.7rem;
}

.breadcrumbContainer {
	padding-top: 0.7rem;
}

.documentsContainer {
	margin-top: 1.5rem;

	table {
		width: 100%;
		font-family: Mulish;
		font-size: 16px;
		font-weight: 400;
		line-height: 24px;
		color: #212529;
		table-layout: fixed;

		thead {
			border-bottom: 2px solid #212529;

			th {
				font-family: Mulish;
				font-size: 16px;
				font-weight: 600;
				line-height: 24px;
				color: #212529;
				padding-bottom: 4px;
				vertical-align: bottom;
			}

			.nameHeader {
				width: 45%;
			}
			.containsHeader {
				width: 15.5%;
			}
			.dateHeader {
				width: 13%;
			}
			.statusHeader {
				width: 18%;
			}
		}
	}
}

:global .filterDocumentsModal {
	.modal-dialog {
		position: absolute;
		top: 0px !important;
		right: 0px !important;
		width: 351px;
		height: 100vh;
		max-height: 100vh;
		margin: 0;
		background: white;
		border: none;
		border-radius: 0px;

		.modal-content {
			.modal-header {
				padding: 2rem 1rem 1rem;
				border: 0px;

				.modal-title {
					font-family: Mulish;
					font-size: 18px;
					font-weight: 700;
					line-height: 21.6px;
					color: #212529;
				}
			}
		}
	}
}

:global .cpaMessageModal {
	.modal-dialog {
		padding: 28px 0px 28px 0px;

		.modal-content {
			.modal-header {
				width: 698px;
				height: 57px;
				border-bottom:1px solid;
				border-color: $platinum !important;
				padding: 16px !important;
				gap: 8px;

				.modal-title {
					width: 642px;
					height: 24px;
					font-family: Mulish;
					font-weight: 700 !important;
					font-size: 20px !important;
					line-height: 24px !important;
					color: $black !important;
				}

				.closeIcon {
					width: 32px !important;
					height: 32px;
				}
			}
		}
	}
}
