@import 'src/styles/variables';

.ccRecipientModalPopup {
	overflow-y: hidden !important;
	font-family: Mulish;

	.modal-dialog {
		display: flex;
		width: 685px;
		max-width: 685px !important;
		max-height: calc(100vh - 3rem);

		.modal-header {
			background-color: #fff;
			border-bottom: 1px solid #dee2e6;
			border-top-left-radius: 4.8px;
			border-top-right-radius: 4.8px;
			color: #212529;
			font-size: 20px;
			line-height: 24px;
			padding: 16px;
			display: flex;
			justify-content: space-between;
			align-items: flex-start;
			font-weight: 500 !important;

			.closeIcon {
				color: #212529;
				cursor: pointer;
			}
		}

		.modal-body {
			height: 500px;
			overflow-y: auto;
		}
	}

	input[type='checkbox'] {
		accent-color: $brand-primary-blue-2;
	}

	.ccRecipientModalDescription {
		font-size: 14px;
		font-weight: 600;

		.ssr_default_email {
			color: $brand-primary-blue-1;
			text-decoration: underline;
		}
	}

	.chkCcRecipientModalSelect {
		cursor: pointer;
		height: 16px;
		width: 16px;
		border-radius: 4px;
		border: 1px solid var(--components-checkbox-border, rgba(0, 0, 0, 0.15));
		background: var(--gray-white, #fff);
	}

	.section_header_text {
		font-size: 16px;
		font-weight: 700;
		margin: 0.8em 0;
		line-height: 24px;
	}

	.lblCcRecipientModalSelectAll {
		font-style: normal;
		font-weight: 400;
		line-height: 24px;
		padding-left: 7px;
		font-size: 16px;
		position: absolute;
		margin-top: -2px;
	}

	.ccRecipientModalDownloadableDocument {
		padding-left: 7px;
		margin-bottom: 0.2em;
		font-size: 16px;
		position: absolute;
		margin-top: -2px;
		line-height: 24px;
	}

	.ccRecipientModalBodyContent {
		margin-top: 0.1em;
	}

	.labelCCRecipientEmail {
		display: flex;
		width: 348px;
		padding-bottom: 0px;
		flex-direction: column;
		align-items: flex-start;
		margin-right: 10px;
	}

	.inputCCRecipientEmail {
		display: flex;
		width: 348px;
		align-items: center;
		height: 31px;
		border-radius: 2px;
		padding: 4px 8px;
		flex: 1 0 0;
		border-radius: 2px;
		border: 1px solid $input-border-color;
		background: $white;
		margin-right: 10px;
		margin-bottom: 0.5rem;
		font-family: $font-family;
		outline: none;
	}

	.inputCCRecipientMobile {
		border-radius: 0px 2px 2px 0px;
		background: $white;
		margin-bottom: 0.5rem;

		.form-control {
			height: 31px;
			display: flex;
			align-items: center;
			width: 190px;
			border: 1px solid $input-border-color;
			font-family: $font-family;
		}

		.flag-dropdown {
			border: 1px solid $input-border-color !important;
			border-radius: 0 0 0 0 !important;
			font-family: $font-family;
		}

		.flag-dropdown ul {
			width: 250px;
		}
	}

	.deleteContactContainer {
		cursor: pointer;
		margin-left: 12px;
		margin-top: 2px;

		img {
			margin-top: -2px;
		}

		span {
			margin-left: 4px;
			font-family: Mulish;
			font-size: 14px;
			font-style: normal;
			font-weight: 400;
			line-height: 21px;
		}

		svg {
			margin-top: -2px;
		}
	}

	.btnAddNewCCRecipient {
		display: flex;
		width: 175px;
		height: 31px;
		padding: 4px 14px;
		align-items: center;
		gap: 7px;
		border-radius: 2px;
		border: 1px solid $input-border-color;
		cursor: pointer;
		font-size: 14px;
	}

	.labelCCRecipientEmail,
	.labelCCRecipientMobile {
		font-weight: 600;
		font-size: 14px;
		line-height: 21px;
	}
}

.closeCCRecipientPopover {
	opacity: 1;
	color: black;
	font-weight: 400;
	margin-top: 3% !important;
	margin-left: 50%;
	z-index: 9999999 !important;
	position: absolute;

	.popover-arrow {
		display: none !important;
	}

	.popover_header {
		background-color: #f2f2f2 !important;
		border-bottom: 1px solid #dee2e6;
		border-top-left-radius: 4.8px;
		border-top-right-radius: 4.8px;
		color: #212529;
		font-size: 20px;
		line-height: 24px;
		padding: 9px 1rem;
		font-weight: 500;

		button.close {
			background-color: initial;
			border: 0;
			padding: 0;
			color: #000;
			float: right;
			font-size: 1.5rem;
			font-weight: 700;
			line-height: 1;
			opacity: 0.5;
			text-shadow: 0 1px 0 #fff;
		}
	}

	.popover_body {
		font-size: 16px;
		line-height: 1.5;
		padding: 16px;
		border-bottom: 1px solid #dee2e6;
		font-family: Mulish, sans-serif;
	}

	.button-margin-right {
		margin-right: 8px !important;
	}

	.button-medium {
		min-height: 38px !important;
		min-width: 100px !important;
	}

	.modal_footer {
		background: #fff;
		border-bottom-left-radius: 4.8px;
		border-bottom-right-radius: 4.8px;
		padding: 12px;
		display: flex;
		flex-wrap: wrap;
		justify-content: flex-end;
		align-items: center;
	}
}

.marTop12 {
	margin-top: 12px;
}
