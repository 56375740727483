@import 'src/styles/variables';

#image-renderer {
    background-image: none;
    background-color: $white;
    height: 70vh;
    border-bottom-left-radius: 4.8px !important;
    border-bottom-right-radius: 4.8px !important;
}

#react-doc-viewer {
    max-height: 85vh;
    height: calc(100vh - 100px) !important;
    border-bottom-left-radius: 4.8px !important;
    border-bottom-right-radius: 4.8px !important;
    padding-bottom: 5px !important;
}

.sse-pdf-viewer-container {
    height: calc(100vh - 100px);
    overflow: hidden;
    border-bottom-left-radius: 4.8px;
    border-bottom-right-radius: 4.8px;
}

.non-supported-file-view {
    height: 760px !important;
    width: 1100px !important;

    .page-viewer {
		display: flex;
		justify-content: center;
		align-items: center;
	}
}
