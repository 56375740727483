@import 'src/styles/variables';

.taxPaymentsContainer {
	margin-top: 1.5rem;

	table {
		width: 100%;
		font-family: Mulish;
		font-size: 16px;
		font-weight: 400;
		line-height: 24px;
		color: $black;
		table-layout: fixed;

		thead {
			border-bottom: 2px solid $black;

			th {
				font-family: Mulish;
				font-size: 16px;
				font-weight: 400;
				line-height: 24px;
				color: $black;
				padding-bottom: 4px;
				vertical-align: bottom;
			}

			.nameHeader {
				width: 65%;
			}
			.dateHeader {
				width: 20%;
			}
		}
	}
}

.taxPaymentRow {
	height: 41px;
	vertical-align: middle;
	font-family: Mulish;
	font-size: 16px;
	font-weight: 400;
	line-height: 24px;
	border-bottom: 1px solid #a6a9ac;

	td {
		vertical-align: middle;

		.nameContainer {
			display: inline-block;
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
			margin-top: 4px;

			svg {
				margin-top: -4px;
				margin-right: 0.5rem;
			}
		}

		.actionsContainer {
			display: flex;
			align-items: center;
			justify-content: flex-start;
			gap: 0.5rem;
			color: $brand-primary-blue-1;

			.verticalDivider {
				display: flex;
				align-items: center;
				cursor: none;
			}

			span{
				cursor: pointer;
			}
		}
	}

	.nameData {
		width: 31%;
	}
	.dateData {
		width: 13%;
	}
}

