.notification {
	.notificationIcon {
		right: 47px;
		cursor: pointer;
		position: absolute;
		top: 20px;
		z-index: 1;
	}

	.notificationDot {
		width: 18px;
		height: 18px;
		border-radius: 50%;
		background-color: #cc4a43;
		position: absolute;
		top: 16px;
		right: 40px;
		z-index: 1;

		font-size: 12px;
		line-height: 18px;
		font-weight: 700;
		font-family: Mulish;
		display: flex;
		justify-content: center;
		align-items: center;
		color: #fff;
		letter-spacing: -0.36px;
		padding-top: 1px;
	}

	.extraPadding {
		padding-left: 1.5px;
	}
}
