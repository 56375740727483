#updateRecoverySection {
	div {
		padding: 5px 0px;
	}

	.btnDefault {
		width: 112px;
		height: 38px;
		padding: 6px 12px 6px 12px;
		border-radius: 2px;
		border: 1px solid #0973ba;
		color: #0973ba;
		background-color: #ffffff;
		margin-top: 5px;
	}

	.btnDefault:hover {
		background: #eee;
	}

	.recoveryOptionValue {
		font-family: Mulish;
		font-size: 14px;
		font-weight: 700;
		line-height: 21px;
		text-align: left;
		color: #565a5e;
	}
}
