.pdf-viewer-left-panel {
	.accordion {
		--bs-accordion-color: var(--bs-body-color);
		--bs-accordion-bg: var(--bs-body-bg);
		--bs-accordion-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
			border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease;
		--bs-accordion-border-color: #c4c6c8;
		--bs-accordion-border-width: 1;
		--bs-accordion-border-radius: 0;
		--bs-accordion-inner-border-radius: 0;
		--bs-accordion-btn-padding-x: 10px;
		--bs-accordion-btn-padding-y: 10px;
		--bs-accordion-btn-color: var(--bs-body-color);
		--bs-accordion-btn-bg: var(--bs-accordion-bg);
		--bs-accordion-btn-icon: url(data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27none%27 stroke=%27%23212529%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27%3e%3cpath d=%27M2 5L8 11L14 5%27/%3e%3c/svg%3e);
		--bs-accordion-btn-icon-width: 16px;
		--bs-accordion-btn-icon-transform: rotate(-180deg);
		--bs-accordion-btn-icon-transition: transform 0.2s ease-in-out;
		--bs-accordion-btn-active-icon: url(data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27none%27 stroke=%27%23052c65%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27%3e%3cpath d=%27M2 5L8 11L14 5%27/%3e%3c/svg%3e);
		--bs-accordion-btn-focus-box-shadow: none;
		--bs-accordion-body-padding-x: 0;
		--bs-accordion-body-padding-y: 0;
		--bs-accordion-active-color: #212529;
		--bs-accordion-active-bg: #f5f5f5;
	}
	.accordion-button {
		font-family: Mulish;
		font-size: 16px;
		font-weight: 500;
		line-height: 19.2px;
		text-align: left;
		background-color: #f5f5f5;
	}
	.accordion-header {
		background-color: #f5f5f5;
	}
	.accordion-button:not(.collapsed) {
		color: #212529;
		background-color: #f5f5f5;
		box-shadow: none;
	}
	.accordion-item:first-of-type {
		border-top: 1px #c4c6c8 solid;
	}
	.accordion-button.collapsed {
		border-bottom: 1px #c4c6c8 solid;
	}
	.accordion-body {
		ul {
			list-style: none;
			margin: 0;
			padding: 0;
			li {
				padding: 5px 10px;
				border: 1px dotted #ddd;
				cursor: pointer;
				font-family: Mulish;
				font-size: 16px;
				font-weight: 500;
				line-height: 19.2px;
				text-align: left;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
			}
		}
		padding: 0;
		background: #ffffff;
	}
}
