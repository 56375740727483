.resetPinContainer {
	margin-top: 1.6rem;
	.resetPin {
		width: 370px;
		height: 38px;
		padding: 6px 12px;
		border-radius: 2px;
		border: 1px solid #0973ba;
		background-color: #0973ba;
		font-family: Mulish;
		font-size: 16px;
		font-weight: 400;
		line-height: 24px;
		color: #fff;
		cursor: pointer;
        }
	}

.lockedIcon{
    margin-bottom: 1.6rem;
}