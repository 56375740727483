.tabHeader {
    display: flex;
    border-bottom: 1px solid #C4C6C8;
    padding: 0.8rem 0 0 0;
    margin-bottom: 0.3rem;
    font-size: 1rem;

    .tabSelected {
        color: #0973BA;
        border-bottom: 3px solid #0973BA;
        font-weight: 700;
        cursor: default;
    }
    
    li {
        list-style: none;
        padding: 0.5rem 1rem;
        margin-bottom: -1px;
        font-weight: 400;
        cursor: pointer;
    }
}

