.mergeEmailContainer {
	margin: 1.5rem 0 1rem 0;
	padding-left: 0px;

	ul {
		padding-left: 0px;
		margin-bottom: 0px;
		width: 415px;

		li:nth-child(even) {
			background: #f0f1f1;
		}
	}

	.emailListHeader {
		display: flex;
		align-items: center;
		padding: 6px 0 0 0;

		.emailHeader,
		.actionHeader {
			font-family: Mulish;
			font-size: 14px;
			font-weight: 700;
			line-height: 21px;
		}

		.emailColumn {
			display: inline-block;
			width: 320px;
		}

		span:nth-child(2) {
			margin-left: 0.5rem;
		}
	}

	.mergeEmailRow {
		display: flex;
		align-items: center;
		list-style: none;
		padding: 6px 0;

		.emailColumn {
			display: inline-block;
			width: 320px;
		}

		.emailData {
			font-family: Mulish;
			font-size: 16px;
			font-weight: 400;
			line-height: 24px;
			color: #212529;
			text-overflow: ellipsis;
			overflow: hidden;
			white-space: nowrap;
		}

		.actionData {
			display: flex;
			align-items: center;
			gap: 0.5rem;
			font-family: Mulish;
			font-size: 16px;
			font-weight: 400;
			line-height: 24px;
		}

		span:nth-child(2) {
			margin-left: 0.5rem;
		}

		.inputEmailContainer {
			.mergeEmailInput {
				width: 314px;
				height: 38px;
				padding: 6px 12px;
				border-radius: 4px;
				border: 1px solid #898d91;
				outline: none;
				font-family: Mulish;
				font-size: 16px;
				font-weight: 400;
				line-height: 24px;
				color: #212529;
			}

			.mergeEmailInput:disabled {
				background-color: #f0f1f1 !important;
				border: 1px solid #c4c6c8 !important;
				color: #c4c6c8 !important;
				cursor: not-allowed;
				box-shadow: 0 0 0px 1000px #f0f1f1 inset;
				-webkit-text-fill-color: #c4c6c8 !important;
			}

			.mergeEmailErrorBorder {
				border: 1px solid #cc4a43;
			}
		}

		.addEmailButtonContainer {
			.addEmailButton {
				width: 112px;
				height: 38px;
				padding: 6px 12px;
				border-radius: 2px;
				border: 1px solid #0973ba;
				background-color: #0973ba;
				color: #fff;
				font-family: Mulish;
				font-size: 16px;
				font-weight: 400;
				line-height: 24px;

				span {
					display: flex;
					align-items: center;
					justify-content: center;
					gap: 0.5rem;
				}
			}

			.addEmailButton:disabled {
				background-color: #f0f1f1 !important;
				border: 1px solid #c4c6c8 !important;
				color: #c4c6c8 !important;
				cursor: not-allowed;
			}
		}
	}

	.addEmailError {
		font-family: Mulish;
		font-size: 14px;
		font-weight: 400;
		line-height: 21px;
		color: #cc4a43;
		padding-top: 0px !important;
		padding-bottom: 0px !important;
	}
}
