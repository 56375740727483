@import '../../../styles/variables.scss';

.documentsHeadContainer {
	padding-top: 1rem;

	.headActionsContainer {
		display: flex;
		align-items: center;
		justify-content: space-between;

		.leftActions {
			display: flex;
			align-items: center;
			gap: 1.5rem;

			.filter {
				display: flex;
				align-items: center;
				gap: 0.5rem;
				border: 0px;
				outline: none;
				background: none;
				font-family: Mulish;
				font-size: 16px;
				font-weight: 400;
				line-height: 24px;
				color: #05386b;

				.count {
					font-weight: 700;
				}
			}
		}

		.rightActions {
			display: flex;
			align-items: center;
			gap: 0.5rem;

			.btnSendFiles {
				width: 150px;
				height: 38px;
				padding: 6px 12px;
				border-radius: 2px;
				border: 1px solid $primary-blue;
				background-color: $primary-blue;
				color: $white;

				.sendFilesIcon{
					margin-right: 8px;
				}
			}

			.btnSendFiles:hover {
				background-color: $primary-blue-hover;
			}
		}
	}
}