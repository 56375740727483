.contentContainer {
	padding-top: 1rem;
	header {
		font-family: Mulish;
		font-size: 32px;
		font-weight: 300;
		line-height: 38.4px;
		color: #212529;
		padding: 2rem 0 1rem 0;
	}

	.description1 {
		font-family: Mulish;
		font-size: 20px;
		font-weight: 500;
		line-height: 24px;
		color: #212529;
	}

	.description2 {
		padding-top: 1.5rem;
		font-family: Mulish;
		font-size: 14px;
		font-weight: 500;
		line-height: 16.8px;
		color: #212529;
	}
}
