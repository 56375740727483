.otpInputControlWrapper {
	display: flex;
	position: relative;

	.inputOuterContainer {
		display: flex;
		gap: 1rem;
		align-items: center;
	}

	.inputContainer {
		display: flex;
		flex-direction: row;
	}

	.invalidFeedback {
		position: absolute;
		bottom: -30px !important;
		font-family: Mulish;
		font-size: 14px;
		font-weight: 400;
		line-height: 21px;
		color: #cc4a43;
		height: 23px;
	}

	input {
		width: 34px;
		height: 41px;
		border-radius: 2px;
		border: 1px solid var(--gray-500, #6b7075);
		background: #f5f6fb;
		font-size: 32px;
		padding: 0px;
		color: var(--body-text-body-color, #212529);
		text-align: center;
		font-family: Mulish;
		font-style: normal;
		font-weight: 400;
		line-height: 38.4px;
	}

	input:focus-visible,
	input:focus,
	input:focus-within {
		outline: none;
	}

	input:not(:last-child) {
		margin-right: 18px;
	}

	.icon {
		color: var(--error-red, #cc4a43);
		margin-left: 10px;
	}

	.error-text {
		width: 100%;
		font-size: '14px';
		color: var(--error-red, #cc4a43);
		margin-top: 10px;
	}
}

.otpInputControlWrapper.error input {
	border-color: var(--error-red, #cc4a43);
}
