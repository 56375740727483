@import 'src/styles/variables';

.updateRecoveryPopupModal {
	:global .modal-body {
		background-color: #fff;
		max-height: 320px !important;
		font-family: Mulish;
		line-height: 24px;
	}
	.recoveryEmail {
		width: 370px;
		height: 38px;
		border-radius: 4px;
		padding: 6px 12px 6px 12px;
		border: 1px solid #898d91;
		font-weight: 400;
		color: #212529;
		outline: none;
	}

	.inputRecoveryPhoneNumber {
		input {
			width: 368px;
			height: 38px !important;
			border-radius: 2px !important;
		}
	}

	.inputRecoveryPhoneNumber.error {
		input {
			border: none !important;
		}
	}

	.recoveryEmailPhonNumberTitle {
		height: 48px;
		font-weight: 400;

		.infoText {
			color: #212529;
		}
	}

	.recoveryInputContainer {
		padding: 20px 0px 20px 0px;
	}

	.recoveryOptionLabel {
		padding: 20px 0px 10px 0px;
		font-weight: 600;
		font-size: 14px;
		line-height: 21px !important;
	}

	.error {
		border: 1px solid #cc4a43 !important;
	}

	.errorContainer {
		font-family: Mulish;
		font-size: 14px;
		font-weight: 400;
		line-height: 21px;
		color: #cc4a43;
		margin-top: 0.6rem;
	}

	.errorBorder {
		border: 1px solid #cc4a43;
	}

	:global .modal-footer {
		background: #fff;
		height: 63px !important;
		justify-content: space-between;

		.btn {
			width: 100px;
			height: 38px;
			border-radius: 2px !important;
		}

		.btn-primary {
			background: $primary-blue;
			border: 1px solid $primary-blue;
			border-radius: 2px;
			color: #ffffff;
			font-size: 1rem;
			margin-left: auto;
		}

		.btn-primary:hover {
			background: $primary-blue-hover;
		}

		.btn-primary:disabled {
			border: 1px solid #c4c6c8;
			background-color: #f0f1f1;
			color: #c4c6c8;
			cursor: not-allowed;
			margin-left: auto;
		}

		.btn-skip {
			background: #ffffff;
			border: 1px solid #0973ba;
			color: #0973ba;
		}
	}
}
