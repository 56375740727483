.bodyStyle {
	font-family: Mulish;
	font-size: 16px;
	font-weight: 400;
	line-height: 24px;
	color: #212529;
	background-color: #fff;
	padding: 1rem 1rem 2rem !important;

	.deleteMessage {
		span {
			font-weight: 700;
		}
	}
}
.footerStyle {
	background-color: #fff !important;

	.noButton {
		width: 100px;
		height: 38px;
		padding: 6px 12px;
		border-radius: 2px;
		border: 1px solid #cc4a43 !important;
		background-color: #cc4a43 !important;
		font-family: Mulish;
		font-size: 16px;
		font-weight: 400;
		line-height: 24px;
		color: #fff;
		margin: 0 !important;
	}

	.yesButton {
		width: 100px;
		height: 38px;
		padding: 6px 12px;
		border-radius: 2px;
		border: 1px solid #5c8001 !important;
		background-color: #5c8001 !important;
		font-family: Mulish;
		font-size: 16px;
		font-weight: 400;
		line-height: 24px;
		color: #fff;
		margin: 0 0 0 0.5rem !important;
	}

	.noButton:disabled,
	.yesButton:disabled {
		opacity: 0.5;
	}

	.noButton:hover {
		border: 1px solid #cc4a43 !important;
		background-color: #cc4a43 !important;
	}

	.noButton:active {
		border: 1px solid #cc4a43 !important;
		background-color: #cc4a43 !important;
	}

	.noButton:focus {
		border: 1px solid #cc4a43 !important;
		background-color: #cc4a43 !important;
	}

	.yesButton:hover {
		border: 1px solid #5c8001 !important;
		background-color: #5c8001 !important;
	}

	.yesButton:active {
		border: 1px solid #5c8001 !important;
		background-color: #5c8001 !important;
	}

	.yesButton:focus {
		border: 1px solid #5c8001 !important;
		background-color: #5c8001 !important;
	}
}
