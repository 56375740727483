.accountSetupWizardModalBody {
    margin: 0;
    padding: 0 !important;
    background: #ffffff;

    .iconSection {
        width: 600px;
        height: 167px;
        background: #e6f1f8b2;
        display: flex;
        justify-content: center;

        .welcomeMessage {
            width: 210px;
            font-family: Mulish;
            font-weight: 300;
            font-size: 30px;
            line-height: 42px;
            text-align: justify;
            align-items: center;
            display: flex;
        }

        .icon {
            padding: 10px 0px;
        }
    }

    .messageContainer {
        padding: 1.5rem;

        .messageTitle {
            font-family: Mulish;
            font-weight: 600;
            font-size: 24px;
            line-height: 36px;
            color: #337ab7;
        }

        .messageDescription {
            font-family: Mulish;
            font-weight: 400;
            font-size: 18px;
            line-height: 27px;
            margin-top: -14px;
        }

        .messageSteps {
            @extend .messageDescription;
            margin-top: 30px;

            ol {
                padding-left: 1.2em;
            }
        }

        .textHighlight {
            font-family: Mulish;
            font-weight: 700;
            font-size: 18px;
            line-height: 27px;
        }
    }
}

.accountSetupWizardModalFooter {
    background: #ffffff !important;
    justify-content: center !important;

    .checkBoxConsent {
        height: 23px;
        width: 16px;
    }

    .checkBoxLabel {
        font-family: Mulish;
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
    }
}
