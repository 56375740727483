@import 'src/styles/variables';

.pin-update-popup-modal {
	.modal-body {
		padding-top: 0px;
		padding-left: 28px;
		background-color: #fff;
		height: 340px;

		.pinContainer {
			display: flex;
			align-items: center;
			width: 300px;
			height: 31px;
			border-radius: 3.2px;
			border: 1px solid #898d91;

			.pin,
			.currentPIN,
			.confirmPIN {
				width: 275px;
				height: 25px;
				left: 20px;
				padding: 6px 12px;
				font-family: Mulish;
				border-radius: 3.2px;
				border: 0px !important;
				font-size: 16px;
				font-weight: 400;
				line-height: 24px;
				color: #565a5e;
				outline: none;
			}

			.eyeContainer {
				cursor: pointer;
			}
		}
		.error {
			border: 1px solid #cc4a43 !important;
		}

		.errorContainer {
			font-family: Mulish;
			font-size: 14px;
			font-weight: 400;
			line-height: 21px;
			color: #cc4a43;
			margin-top: 0.6rem;
		}
	}

	.modal-footer {
		background: #fff;

		.btn-default {
			background: #f0f1f1;
			border: 1px solid $input-border-color;
			border-radius: 2px;
			color: #202223;
			font-size: 1rem;
			width: 125px;
		}

		.btn-default:hover {
			background: #d6dada;
		}

		.btn-primary:hover {
			background: $primary-blue-hover;
		}
		.btn-primary {
			background: $primary-blue;
			border: 1px solid $primary-blue;
			border-radius: 2px;
			color: #ffffff;
			font-size: 1rem;
			width: 125px;
		}
		.btn-primary:disabled {
			border: 1px solid #c4c6c8;
			background-color: #f0f1f1;
			color: #c4c6c8;
			cursor: not-allowed;
		}
	}
}
