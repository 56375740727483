.searchContainer {
	display: flex;
	position: relative;

	input {
		outline: none;
		border: 1px solid #898d91;
		padding: 6px 12px;
		width: 100%;
		height: 100%;
		border-radius: 4px 0 0 4px;
		font-family: Mulish;
		font-size: 16px;
		font-weight: 400;
		line-height: 24px;
		color: #212529 !important;
	}

	.searchIcon {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 42px;
		height: 100%;
		border: 1px solid #6b7075;
		background-color: white;
		padding: 6px 12px;
		margin-left: -1px;
		border-radius: 0 4px 4px 0;
		cursor: pointer;

		svg {
			width: 1rem;
		}
	}

	.searchIcon:disabled {
		cursor: not-allowed;
		svg {
			opacity: 0.3;
		}
	}

	.noSearchOnClick {
		cursor: default;
	}

	.clearSearch {
		display: flex;
		align-items: center;
		justify-content: center;
		position: absolute;
		top: 2px;
		right: 42px;
		width: 2rem;
		height: 92%;
		background: #fff;
		cursor: pointer;

		svg {
			width: 12px;
			height: 12px;
		}
	}
}
