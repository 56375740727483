#awesome-pdf-viewer,
.sse-doc-viewer {
	border-bottom-left-radius: 4.8px;
	border-bottom-right-radius: 4.8px;
	.download-btn {
		float: right;
		width: 125px;
		height: 31px;
		padding: 4px 8px 4px 8px;
		border-radius: 2px;
		border: 1px solid #0973ba;

		font-family: Mulish;
		font-size: 14px;
		font-weight: 400;
		line-height: 21px;
		text-align: left;
		color: #0973ba;
		background-color: #fff;
	}
	.download-btn:hover {
		background-color: #054570;
		text-decoration: underline;
		color: #fff;
	}

	.download-btn svg {
		margin: 3px 6px;
	}
	.download-btn:hover svg path {
		fill: #fff;
	}
	.non-supported-file-container {
		font-family: Mulish;
		font-size: 18px;
		font-weight: 500;
		line-height: 27px;
		text-align: center;
		margin: 30% 10%;
		width: auto;
		height: 54px;
	}
	.main {
		height: 80vh !important;
	}

	.main .left-panel {
		border-bottom-left-radius: 4.8px;
	}
}

.gtr-document-preview {
	.middle-section {
		display: flex;
		justify-content: center;
		& > p {
			margin: 0;
		}
	}
	#awesome-pdf-viewer .download-btn {
		float: none;
		margin-left: 30px;
	}
}
