@import 'src/styles/variables.scss';

.headerContactInformation {
    font-family: $font-family;
    font-size: 18px;
    font-weight: 700;
    line-height: 22.59px;
    text-align: left;
    color: #212529;
}

.body{
    margin-top: 7%;
}

.bodyTitleContainer {
    font-family: $font-family;
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;
    text-align: left;
    color: #6B7075;
}
.bodyValueContainer {
    font-family: $font-family;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    text-align: left;
    color: #212529;
    margin-top: 1%;
    margin-bottom: 4%;
}

.phoneNumberInput {
    font-family: $font-family;
    border: none !important;
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: 21px !important;
    text-align: left !important;
    color: #212529 !important;
    text-align: left;
    padding-left: 1% !important;
    background-color: #fff !important;
    width: fit-content !important;
    cursor: text !important;
    height: 21px !important;
}

.countryCodeContainer {
    display: none !important;
}