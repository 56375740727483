@import 'src/styles/variables';

.message-container {
    background: white;
    width: 698px;
    height: 100%;
    border-bottom-left-radius: 4.8px;
    border-bottom-right-radius: 4.8px;

    .message-content {
        max-width: 698px;
        width: 100%;
        max-height: calc(100vh - 195px);
        height: auto;
        overflow-y: auto;
        padding: 16px;
        gap: 16px;

        .message {
            max-width: 666px;
            width: 100%;
            height: 100%;
            padding: 0px 0px 16px 0px;
            gap: 0px;
            font-size: 16px;
            line-height: 24px;
            font-weight: 400;
            font-family: Mulish;
            color: $black;
        }
    }

    .modal-footer{
        display: flex;
        justify-content: flex-end;
        align-items: center;
        max-width: 698px;
        width: 100%;
        max-height: 63px;
        height: 63px;
        padding: 12px;
        padding-top: 10px;
        gap: 8px;
        border-top: 1px solid;
        border-color: $platinum;

        .ok-button {
            width: 100px;
            max-height: 38px;
            height: 38px;
            padding: 6px 12px 6px 12px;
            gap: 8px;
            border-radius: 2px;
            margin-bottom: 1px;
            border: 1px;
            background-color: $primary-blue;
            border-color: $primary-blue;
            color: $white;
            font-size: 16px;
            line-height: 24px;
            font-family: Mulish;
            font-weight: 400;
        }
    }
}
