.documentsSortClass {
	.sortByDropdown {
		#sortByDropdown {
			border: 1px solid #6b7075;
			border-radius: 2px !important;
			background-color: transparent;
			padding: 0px 0.7rem;
			font-size: 16px;
			width: 104px;
			height: 38px;

			.titleName {
				width: 100%;
				color: #212529;
				display: flex;
				align-items: center;
				justify-content: space-between;
				font-family: Mulish;
				font-size: 16px;
				font-weight: 400;
				line-height: 24px;

				.sortDataType {
					font-family: Mulish;
					font-size: 16px;
					font-weight: 700;
					line-height: 24px;
				}

				.icons {
					display: flex;
					align-items: center;
					gap: 6px;
					margin-top: 2px;

					.cancelIcon {
						display: inline-flex;
						align-items: center;

						svg {
							width: 14px;
							height: 14px;
						}
					}

					.arrowIcon {
						display: inline-flex;
						align-items: center;
					}
				}
			}
		}

		::after {
			display: none;
		}

		.dropdown-menu {
			border: 1px solid #00000026;
			border-radius: 4px;
			padding: 8px 0px;
			font-family: Mulish;
			font-size: 16px;
			font-weight: 400;
			line-height: 24px;
			cursor: default;
			min-width: 140px !important;

			li {
				padding: 4px 1rem;
				line-height: 150%;
				cursor: pointer;
				white-space: nowrap;
				width: 224px;
				display: flex;
				justify-content: space-between;

				.tickIcon {
					display: inline;
				}
			}
			.lessWidth {
				width: 140px !important;
			}

			li:hover {
				background-color: rgb(0, 0, 0, 0.075);
			}
		}
	}

	.bigButton #sortByDropdown {
		width: 224px;
	}
}
