.recentActivitymodule {
	header {
		font-family: Mulish;
		font-size: 18px;
		font-weight: 700;
		line-height: 22.59px;
		color: #212529;
	}

	.recentActivityList {
		margin-top: 1rem;
		margin-bottom: 0px;
		width: 100%;
		min-height: 3rem;
		background: #ddd8c41a;
		border-radius: 4px;
		padding: 0.7rem 0.8rem 0.5rem 0.8rem;

		li {
			list-style: none;
			display: flex;
			justify-content: space-between;

			.left {
				width: calc(100% - 116px);
			}
			.right {
				width: 116px;
			}
		}

		.emptyRecentActivityMessage {
			padding: 1rem 0;
			font-family: Mulish;
			font-size: 14px;
			font-weight: 500;
			line-height: 21px;
			text-align: center;
			color: #212529;
		}

		.listHeader {
			font-family: Mulish;
			font-size: 16px;
			font-weight: 700;
			line-height: 20.08px;
			color: #212529;
			margin-bottom: 0.3rem;

			.left {
				padding: 0 6px;
			}
		}

		.listData {
			font-family: Mulish;
			font-size: 14px;
			font-weight: 400;
			line-height: 21px;
			color: #212529;
			border-top: 1px solid #c4c6c8;

			div {
				height: 42px;
				padding-top: 11px;
			}

			.left {
				padding-left: 6px;
				padding-right: 6px;
				overflow: hidden;
				white-space: nowrap;
				text-overflow: ellipsis;

				svg {
					margin-right: 3px;
					margin-top: -3px;
				}
			}

			.left > span {
				text-decoration: underline;
				cursor: pointer;
			}
		}
	}
}
