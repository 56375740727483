.loggedInLayout {
	display: flex;
	flex-direction: column;

	.content {
		height: calc(100vh - 30px);
		display: flex;
	}

	.footer {
		height: 30px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		font-family: Mulish;
		font-size: 12px;
		font-weight: 400;
		line-height: 18px;
		color: #212529;
		background-color: #f0f1f1;
		padding: 0 0.6rem;
	}
}
