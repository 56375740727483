.filterList {
	display: flex;
	gap: 0.5rem;
	flex-direction: row;
	flex-wrap: wrap;

	.statusTag,
	.fileTypeTag,
	.dateTag {
		display: inline-flex;
		align-items: center;
		gap: 0.5rem;
		height: 23px;
		padding: 1px 8px;
		border: 1px solid #898d91;
		border-radius: 4px;

		.name {
			font-family: Mulish;
			font-size: 14px;
			font-weight: 400;
			line-height: 21px;
			color: #212529;
		}

		.closeIcon {
			display: flex;
			align-items: center;
			cursor: pointer;
		}
	}

	.statusTag {
		background-color: #e6f1f8;
	}

	.fileTypeTag {
		background-color: #f0f4ec;
	}

	.dateTag {
		background-color: #fbf0e7;
	}

	.clearAll {
		font-family: Mulish;
		font-size: 14px;
		font-weight: 400;
		line-height: 21px;
		color: #0973ba;
		padding: 0 0.5rem;
		margin-left: 6px;
		background-color: #fff;
		border: none;
	}
}
