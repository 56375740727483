.mobilePageContainer {
	header {
		height: 55px;
		background-color: #075c95;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.mainContent {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		gap: 1.5rem;
		height: calc(100vh - 55px - 5rem);
		padding: 0 1rem;

		p {
			font-family: Mulish;
			font-size: 18px;
			font-weight: 500;
			line-height: 27px;
			text-align: center;
			color: #212529;
			margin: 0px;
		}
	}
}
