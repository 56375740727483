.accessDenied {
    font-family: Mulish;
    font-size: 32px;
    font-weight: 300;
    line-height: 38.4px;
    text-align: left;
    color: #212529;
    padding-top: 4%;
}
.disabledText {
    font-family: Mulish;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    color: #212529;
}
.disabledTextContainer {
    padding-top:4%;
}
