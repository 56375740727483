.bodyStyle {
	font-family: Mulish;
	font-size: 14px;
	font-weight: 400;
	line-height: 24px;
	color: #212529;
	background-color: #fff;
	padding: 1rem 1rem 2rem !important;
	max-height: 500px;
	overflow: hidden;
	overflow-y: scroll;

	.taxreturnHeader{
		color: var(--Body-Text-Body-Color, #212529);
		font-size: 12px;
		font-style: normal;
		font-weight: 700;
		line-height: normal;
	}

	.greenColor {
		color: #669440;
	}

	.redColor {
		color: #CC4A43;
	}

	section > .paySectionTitle {
		clear: both;
		color: #898d91;
		font-size: 14px;
		font-weight: 700;
		line-height: 22px;
	}
	
	.currencyText {
		font-family: sans-serif, Roboto, Poppins;
		padding-right: 0;
		text-align: right;
	}
	
	.floatRight {
		float: right;
	}
	
	.floatLeft {
		float: left;
	}

	.payAmount {
		text-align: right;
		font-size: 14px;
		font-style: normal;
		font-weight: 700;
	}
	
	.authorityEllipsis {
		color: var(--Body-Text-Body-Color, #212529);
		font-style: normal;
		font-weight: 400;
		display: block;
		overflow: hidden;
		padding: inherit;
		text-overflow: ellipsis;
		white-space: nowrap;
	}
	
	.marginTop20{
		margin-top: 20px;
	}
	
	.payment-info-modal{
		width: 599px !important;
	}
	
	.totalAmountGreen, .totalAmountRed {
		border-top: .5px solid #6b7075;
		font-weight: 800;
		width: 40% !important;
	}
	.totalAmountGreen {
		letter-spacing: normal;
		line-height: 22px;
	}
	
	.subSection div {
		position: relative;
		width: 58%;
	}
	
	.subSection > div > span{
		font-style: normal;
		font-weight: 800;
		line-height: 22px;
	}
}

.footerStyle {
	background-color: #fff !important;

	.payButton {
		width: 100px;
		height: 38px;
		padding: 6px 12px;
		border-radius: 2px;
        border: 1px solid var(--Gray-400, #898D91) !important;;
        background: var(--Gray-100, #F0F1F1) !important;;
		font-family: Mulish;
		font-size: 16px;
		font-weight: 400;
		line-height: 24px;
        color: var(--Body-Text-Body-Color, #212529);
		margin: 0 !important;
	}

	.okButton {
		width: 100px;
		height: 38px;
		padding: 6px 12px;
		border-radius: 2px;
		border: 1px solid #0973ba !important;
		background-color: #0973ba !important;
		font-family: Mulish;
		font-size: 16px;
		font-weight: 400;
		line-height: 24px;
		color: #fff;
		margin: 0 0 0 0.5rem !important;
	}

	.payButton:disabled,
	.yesButton:disabled {
		opacity: 0.5;
	}
}


