.toaster_container {
	.toaster {
		margin-top: 1rem;
		width: 408px;
		font-size: 16px;
	}

	.toaster-common {
		font-family: 'Mulish', sans-serif;
		font-size: 16px;
		font-weight: 500;
		line-height: 24px;
		color: #212529;
		border-radius: 4px;
		padding: 16px 16px 16px 8px;
		display: flex;
		align-items: center;
		min-height: 56px;
		box-shadow: none !important;
	}

	.toaster-error {
		@extend .toaster-common;
		background-color: #faedec;
		border-left: 5px #b8433c solid;
	}

	.toaster-warning {
		@extend .toaster-common;
		background-color: #fbf5eb;
		border-left: 5px #d69f38 solid;
	}

	.toaster-success {
		@extend .toaster-common;
		background-color: #e9f3ed;
		border-left: 5px #1f8747 solid;
	}

	.toaster-icon-message {
		display: flex;
		align-items: center;

		svg {
			width: 20px;
		}

		.toaster-message {
			margin: 0 16px 0 8px;
			white-space: pre-line;
		}
	}
}
